<template>
  <div class="admin-edit-topic">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <div class="page-header">
      <h1 class="page-title">Edit Topic</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <div class="top-row">
        <div
          class="left"
          :class="{
            'has-custom-avatar': !shouldRemoveExistingButtonStrip,
          }"
        >
          <a-form-item label="Button Strip">
            <div
              v-if="!shouldRemoveExistingButtonStrip && !fileList.length"
              class="custom-avatar"
            >
              <div class="left">
                <topic-button-strip
                  size="large"
                  :topic="topic"
                  :tenant-id="tenantId"
                ></topic-button-strip>
              </div>
              <div class="right">
                <a-popconfirm
                  title="Are you sure?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="shouldRemoveExistingButtonStrip = true"
                >
                  <a href="#">Remove</a>
                </a-popconfirm>
              </div>
            </div>

            <a-upload
              :multiple="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :remove="handleRemove"
            >
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to
                {{
                  !shouldRemoveExistingButtonStrip || fileList.length
                    ? "change"
                    : "upload"
                }}
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
        <div class="right">
          <a-form-item label="Topic Name">
            <a-input size="large" v-model="topicLocal.displayName"></a-input>
          </a-form-item>
        </div>
      </div>

      <a-form-item label="Context Quote">
        <a-input size="large" v-model="topicLocal.contextQuote"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="topicLocal.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="Display Order">
        <a-radio-group v-model="topicLocal.rank">
          <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
            rank.label
          }}</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Form -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
import topics from "../../../../api/guidance/topics";
import TopicButtonStrip from "./TopicButtonStrip.vue";
import guidanceHelpers from "../../../../helpers/guidance";

export default {
  components: { LoadingScreen, TopicButtonStrip },

  data() {
    return {
      topicLocal: {},

      fileList: [],
      shouldRemoveExistingButtonStrip: false,

      isSaving: false,
    };
  },

  created() {
    if (!this.topic) {
      this.$message.info("Topic not found");
      this.$router.push("/admin/guidance/topics");
    } else {
      this.topicLocal = {
        ...this.topic["$v"],
      };
    }
  },

  methods: {
    ...mapActions('adminTopics', {
      updateTopicLocally: 'updateTopicLocally'
    }),

    goBack() {
      this.$router.push("/admin/guidance/topics/" + this.topic["$v"].id);
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.fileList = [file];
      }
      return false;
    },

    getValidationErrors() {
      let errors = [];
      if (this.topicLocal.displayName.trim().length == 0) {
        errors.push("Please provide a topic name");
      }
      if (this.topicLocal.contextQuote.trim().length == 0) {
        errors.push("Please provide a context quote");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      topics
        .updateTopic(vm.tenantId, vm.topic['$v'].id, this.topicLocal)
        .then(async (r) => {
          if (this.fileList.length) {
            try {
              await topics.addTopicButtonStrip(
                vm.tenantId,
                this.topic['$v'].id,
                vm.fileList[0]
              );
            } catch (e) {
              console.log(e);
              vm.$message.error("Error upading topic button strip");
            }
          } else if (this.shouldRemoveExistingButtonStrip) {
            await topics.deleteTopicButtonStrip(this.tenantId, this.topic['$v']['id'])
          }

          vm.updateTopicLocally(r.data);

          vm.$message.success("Topic updated successfully");
          vm.$router.push("/admin/guidance/topics/" + vm.topicId);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("adminTopics", {
      topics: "topics",
    }),

    topicId() {
      return this.$route.params.id;
    },

    topic() {
      let vm = this;
      return _.find(this.topics, (t) => {
        return t["$v"].id == vm.topicId;
      });
    },

    ranks() {
      return guidanceHelpers.listRankOptions();
    },
  },
};
</script>

<style lang="scss">
.admin-edit-topic {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .has-custom-avatar {
    .ant-form-item-children {
      display: flex !important;
      flex-direction: horizontal;

      .custom-avatar {
        padding-right: 15px;
      }
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .custom-avatar {
    margin-bottom: 15px;
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>